// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-completed-jsx": () => import("./../../../src/pages/completed.jsx" /* webpackChunkName: "component---src-pages-completed-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mixtape-1-jsx": () => import("./../../../src/pages/mixtape-1.jsx" /* webpackChunkName: "component---src-pages-mixtape-1-jsx" */),
  "component---src-pages-mixtape-2-jsx": () => import("./../../../src/pages/mixtape-2.jsx" /* webpackChunkName: "component---src-pages-mixtape-2-jsx" */),
  "component---src-pages-zine-jsx": () => import("./../../../src/pages/zine.jsx" /* webpackChunkName: "component---src-pages-zine-jsx" */),
  "component---src-templates-single-item-jsx": () => import("./../../../src/templates/single-item.jsx" /* webpackChunkName: "component---src-templates-single-item-jsx" */)
}

